import recipe from "@/recipe";

const uploadPath = `${recipe}/upload`;
const storage = {
  incomingLetter: `${uploadPath}/incoming-letters/`,
  outgoingLetter: `${uploadPath}/outgoing-letters/`,
  invoiceCustomer: `${uploadPath}/invoice-customers/`,
  learningCenter: `${uploadPath}/technical/learning-center/`,
  purchase: `${uploadPath}/purchases/`,
  avatar: `${uploadPath}/avatar/`,
  equipment: `${uploadPath}/equipments/`,
  sparepart: `${uploadPath}/spareparts/`,
};

export default storage;
