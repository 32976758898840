<template>
  <div>
    <div  v-if="file != 'null' && file != '-' && file" >
      <a :href="link">
        <feather-icon icon="DownloadIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
      </a>
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="() => params.openPopupUpload(this.params.data)" />
      <!-- <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" /> -->
    </div>
    <vs-button color="primary" type="border" size="small" v-else @click="() => params.openPopupUpload(this.params.data)">Upload</vs-button>
    <!-- <div class="block m-0 absolute top-0">
      <vs-button color="primary" type="border" size="small" @click="() => params.openPopupUpload(this.params.data)">Upload</vs-button>
    </div>
    <div class="block m-0 absolute top-9 left-5">
      <vs-button color="primary" size="small" v-if="file != 'null' && file != '-' && file" :href="link">Download</vs-button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "CellRendererFile",
  computed: {
    file () {
      return this.params.data.file;
    },
    link () {
      return this.params.storage().purchase + this.params.data.file;
    }
  }
}
</script>

