<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  computed: {
    encrypt () {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    }
  },
  methods: {
    showData () {
      this.$router.push({name: 'purchase-show', params: { id: this.encrypt }});
    },
    editRecord () {
      this.$router.push({name: 'purchase-edit', params: { id: this.encrypt }});
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You are about to delete purchase order from customer by ID #${this.params.data.id}`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("purchase/removeItem", this.params.data.id)
        .then((resp) => {

          this.params.getData();

          this.showDeleteSuccess(resp)
        })
        .catch(err => {
          this.showDeleteError(err)
        })
    },
    showDeleteSuccess (resp) {
      this.$vs.notify({
        color: 'success',
        title: 'Delete success',
        text: resp.data.message
      })
    },
    showDeleteError (err) {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    }
  }
}
</script>
